import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

@Pipe({
  name: 'dateTimeFormat1'
})
export class DateTimeFormat1Pipe implements PipeTransform {

  transform(value: any, args?: any): any {
    args = args ? args : 'DD MMM YYYY - h:mm a';
    return moment(value, 'DD/MMM/YYYY h:mm a').format(args);
  }

}
