import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';

moment.locale('es');
@Pipe({
  name: 'timeelapsed'
})
export class TimeelapsedPipe implements PipeTransform {

   transform(value: any): any {

    return moment(value, 'LTS').format('LTS');
  }

}
