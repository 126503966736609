import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WinslowtokenService {
    //
    apiTokenWinslow: any;
    private tokenKey = 'token';


    public setTokenWinslow(data) {
        this.apiTokenWinslow = data;
    }

    public getTokenWinslow() {
        return this.apiTokenWinslow;
    }

    guardarToken(token: string): void {
        localStorage.setItem(this.tokenKey, token);
    }
    obtenerToken(): string | null {
        return localStorage.getItem(this.tokenKey);
    }

    constructor() { }
}
