import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

/*--Pipes--*/
import { DateFormat1Pipe } from './date-format/date-format1.pipe';
import { DateTimeFormat1Pipe } from './dateTime/date-time-format1.pipe';
import { PuntoMilesPipe } from './miles-number/punto-miles.pipe';
import { TimeelapsedPipe } from './timeelapsed/timeelapsed.pipe';

@NgModule({
  declarations: [
    DateFormat1Pipe,
    DateTimeFormat1Pipe,
    PuntoMilesPipe,
    TimeelapsedPipe
  ],
  exports: [
    DateFormat1Pipe,
    DateTimeFormat1Pipe,
    PuntoMilesPipe,
    TimeelapsedPipe
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class SharedPipesModule { }
