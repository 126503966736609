import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'puntoMiles'
})
export class PuntoMilesPipe implements PipeTransform {

  transform(num: any, args?: any): any {
    num = String(num).replace(/\D/g, "");
    return num === '' ? num : Number(num).toLocaleString();
  }

}
