import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

@Pipe({
  name: 'dateFormat1'
})
export class DateFormat1Pipe implements PipeTransform {

  transform(value: any, args?: any): any {
    args = args ? args : 'DD MMM YYYY';
    return moment(value, args).format(args);
  }

}
